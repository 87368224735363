
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46page_93B6221gClypHIxjPWduJSW1WcqVmtTSAgv2lPPCKtC3oMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as bikerouteseGAQv5Qe0HoibORQEL1NSO5hksJmCzYhN9DgJyx2MWsMeta } from "/vercel/path0/pages/bikeroutes.vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93SBY8zAuBqS09EVm6tWZINZnYQYsZvwyMGOqaubs79esMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as index5m4luOcQHke3VZrEYSUnzYkzST3dh3H3ZkRLIEILJ48Meta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as faqjjKwHwl3gHPshJy6Fwl0mtVaKy4g6wmpINdRy4T3GwoMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryDLxFKVENimRRVJJ_459ELiRq_45VfjTVrdrzneLKnokQEnkMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as _91item_93CSfTaw9VLKDSfIQKna_45LKxSzaUlvT8cDI3Ub_45tZ2lZ8Meta } from "/vercel/path0/pages/highlights/[item].vue?macro=true";
import { default as index_JbzJfV1rBQZ6nkBxiwF_45pMh89SXypYLDPscfCRqhiIMeta } from "/vercel/path0/pages/highlights/index.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93lXg433Z09Ktisn5orw_45uMLL2dXxowdWzZ_FDvO31CBwMeta } from "/vercel/path0/pages/landingpages/[item].vue?macro=true";
import { default as indexMva5c1abDFprfOmKSk1KHrcmoqvCg_45b8agCkI6waWTgMeta } from "/vercel/path0/pages/landingpages/index.vue?macro=true";
import { default as pressUfLtDPvDAlsea7_GUb04uKYudW2a4KTwyi_45Y_P8IwUAMeta } from "/vercel/path0/pages/press.vue?macro=true";
import { default as privacyoUxJze_459YVMN19fHCMNcOPA4bNg1TjmwARd6j9aojjEMeta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as restaurantQcX2Qa_45wEiqVN_45eK_xIQLQYcyE6jyZwierjmuPAL4oYMeta } from "/vercel/path0/pages/restaurant.vue?macro=true";
import { default as rooms8oo7h3Sk0_I8UBxyQwpRArI0zAmrdy4EqdQvIhpiCDYMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapnpadVI10GvN_45Y_45o1so1bwWJcyYretVyWs_xkYJE7aFUMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93hh_00XwaGPAQLiyctzWT0PTXCTH4h86sL6TLOX52aqYMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexKmjwSzq9GaAAk3Ey8PsdYuMIJhhwCqzoHwjXqVD_YWMMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "bikeroutes___en",
    path: "/bike-routes",
    component: () => import("/vercel/path0/pages/bikeroutes.vue")
  },
  {
    name: "bikeroutes___nl",
    path: "/nl/fietsroutes",
    component: () => import("/vercel/path0/pages/bikeroutes.vue")
  },
  {
    name: "contact___en",
    path: "/contact-us",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "events-item___en",
    path: "/meet-and-celebrate/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___nl",
    path: "/nl/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events___en",
    path: "/meet-and-celebrate",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights-item___en",
    path: "/do-and-explore/:item()",
    component: () => import("/vercel/path0/pages/highlights/[item].vue")
  },
  {
    name: "highlights-item___nl",
    path: "/nl/bezienswaardigheden/:item()",
    component: () => import("/vercel/path0/pages/highlights/[item].vue")
  },
  {
    name: "highlights___en",
    path: "/do-and-explore",
    component: () => import("/vercel/path0/pages/highlights/index.vue")
  },
  {
    name: "highlights___nl",
    path: "/nl/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/highlights/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "landingpages-item___en",
    path: "/landingpages/:item()",
    component: () => import("/vercel/path0/pages/landingpages/[item].vue")
  },
  {
    name: "landingpages-item___nl",
    path: "/nl/landingpages/:item()",
    component: () => import("/vercel/path0/pages/landingpages/[item].vue")
  },
  {
    name: "landingpages___en",
    path: "/landingpages",
    component: () => import("/vercel/path0/pages/landingpages/index.vue")
  },
  {
    name: "landingpages___nl",
    path: "/nl/landingpages",
    component: () => import("/vercel/path0/pages/landingpages/index.vue")
  },
  {
    name: "press___en",
    path: "/press",
    component: () => import("/vercel/path0/pages/press.vue")
  },
  {
    name: "press___nl",
    path: "/nl/pers",
    component: () => import("/vercel/path0/pages/press.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___nl",
    path: "/nl/privacy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "restaurant___en",
    path: "/eat-and-drink",
    component: () => import("/vercel/path0/pages/restaurant.vue")
  },
  {
    name: "restaurant___nl",
    path: "/nl/restaurant",
    component: () => import("/vercel/path0/pages/restaurant.vue")
  },
  {
    name: "rooms___en",
    path: "/stay-and-sleep",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___nl",
    path: "/nl/stay-sleep",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "vacancies-item___en",
    path: "/work-with-us/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___nl",
    path: "/nl/vacatures/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies___en",
    path: "/work-with-us",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___nl",
    path: "/nl/vacatures",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  }
]